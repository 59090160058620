import React, { useState, useEffect } from 'react'
import { View, Text, Button, useWindowDimensions } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
//import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem} from '@react-navigation/drawer'
import AsyncStorage from '@react-native-async-storage/async-storage'

import HomeScreen from './screens/cluster'
import SignInScreen from './screens/signin'
import SignUpScreen from './screens/signup'
import ForgotPasswordScreen from './screens/fgpass'
import QueryScreen from './screens/query'
import ChangePasswordScreen from './screens/changePW'



const Stack = createStackNavigator();

function App() {

  const linking = {
    prefixes: ['https://monicatool.cloud'],
    config:{
      screens: {
        Home: '',
        SignIn: 'signin',
        SignUp: 'signup',
        myQuery: 'myQuery',
        ChangePassword: 'changepassword',
      }
    }
  }

  const [firstOpen,setFirstOpen] = useState()
  const [idUserTable,setIdUserTable] = useState()
  const [state, setState]= useState(false)
  const [token, setToken] =useState(false)

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@id_usertable')
      if(value !== null) {
        setIdUserTable(value)
        return(value)
      }
    }
    catch(e) {
      // error reading value
    }
  }

  const close = async () =>{
    const value = await AsyncStorage.removeItem('@token')
    window.location.reload()
    //props.navigation.closeDrawer()
  }

  const getToken = async () => {
    try {
      const value = await AsyncStorage.getItem('@token')
      if(value !== null) {
        //setState(true)
        return value
      }
      else{
        setState(false)
      }
    }
    catch(e) {
      // error reading value
    }
  }

  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem('@id_usertable', value)
      setState(true)
    } catch (e) {
      // saving error
    }
  }

  useEffect(()=>{
    getToken().then((token)=>{
      setToken(token)
    })
  }, [firstOpen])
 
  useEffect(()=>{
    if (token){
      getData().then((idTable) =>{
        if (idTable){
          setState(true)
        }
        else{
          fetch('https://monicatool.cloud:3001/api/newUserTable', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+token
            },
          })
          .then((response) => response.json())
          .then((resdata) => {
            storeData(resdata._id)
            setIdUserTable(resdata._id)
          })
          .catch((e) => {
            console.log(e)
          })
        }
      })
      .catch(()=>{
        fetch('https://monicatool.cloud:3001/api/newUserTable', {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': "Bearer "+token
          },
        })
        .then((response) => response.json())
        .then((resdata) => {
          storeData(resdata._id)
          setIdUserTable(resdata._id)
        })
        .catch((e) => {
          console.log(e)
        })
      })
    }
  }, [token])
  

  return (
    state ? (
        <>
          <NavigationContainer linking={linking}>
          <Stack.Navigator>
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={({ navigation, route }) => ({
                title:"MONICA tool",
                // headerLeft: () => (
                //   <Button title="Query" onPress={() => navigation.navigate('Query')} />
                // ),
                headerRight: () => (
                  <>
                  <View style={{flexDirection:'row'}}>
                    <View style={{paddingHorizontal:10}}>
                      <Button title="My Instances" onPress={() => navigation.navigate('Query')} />
                    </View>
                    <View style={{paddingHorizontal:10}}>
                      <Button title="Change Password" onPress={() => navigation.navigate('ChangePassword')} />
                    </View>
                    <View style={{paddingHorizontal:10}}>
                        <Button title="Logout" onPress={() => close()} />
                    </View>
                  </View>
                  </>
                ),
                headerStyle: {
                  backgroundColor: '#292929', 
                },
                headerTitleStyle: { margin: 20 },
                headerLeftContainerStyle: { margin: 20 },
                headerRightContainerStyle: { margin: 20 },
                headerTintColor: '#fff',
              })}
            />
            <Stack.Screen
              name="ChangePassword"
              component={ChangePasswordScreen}
              options={({ navigation, route }) => ({
                title:"Change Password",
                headerStyle: {
                  backgroundColor: '#292929', 
                },
                headerTitleStyle: { margin: 20 },
                headerLeftContainerStyle: { margin: 20 },
                headerRightContainerStyle: { margin: 20 },
                headerTintColor: '#fff',
              })}
            />
            <Stack.Screen
              name="Query"
              component={QueryScreen}
              options={({ navigation, route }) => ({
                title:"My Queries",
                headerStyle: {
                  backgroundColor: '#292929', 
                },
                headerTitleStyle: { margin: 20 },
                headerLeftContainerStyle: { margin: 20 },
                headerRightContainerStyle: { margin: 20 },
                headerTintColor: '#fff',
              })}
            />
          </Stack.Navigator>
          </NavigationContainer>
      </>
      ):(
      <>
        <NavigationContainer linking={linking}>
          <Stack.Navigator>
            <Stack.Screen
              name="SignIn"
              component={SignInScreen}
              options={{
                title:"MONICA tool - SignIn",
                // headerLeft: () => (
                //   <Button title="menu" onPress={() => navigation.openDrawer()} />
                // ),
                headerStyle: {
                  backgroundColor: '#292929'
                },
                headerTintColor: '#fff',
              }}
            />
           <Stack.Screen
            name="SignUp"
            component={SignUpScreen}
            options={{
              title:"MONICA tool - SignUp",
              // headerLeft: () => (
              //   <Button title="menu" onPress={() => navigation.openDrawer()} />
              // ),
              headerStyle: {
                backgroundColor: '#292929'
              },
              headerTintColor: '#fff',
            }}
            />
            <Stack.Screen
              name="ForgotPassword"
              component={ForgotPasswordScreen}
              options={{
                title:"MONICA tool - ForgotPassword",
                // headerLeft: () => (
                //   <Button title="menu" onPress={() => navigation.openDrawer()} />
                // ),
                headerStyle: {
                  backgroundColor: '#292929'
                },
                headerTintColor: '#fff',
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </>
      )
    
  );
}


export default App
