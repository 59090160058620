import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions, Alert } from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { HelperText, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { emailValidator, passwordValidator } from '../utils/index';


const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
  };

function SignInScreen({ navigation, route }) {

    const [email,setEmail] = useState({ value: '', error: '' })
    const [password, setPassword] = useState({ value: '', error: '' });
    const [passwordconfirm, setPasswordConfirm] = useState({ value: '', error: '' });

    const [firstOpen,setFirstOpen] = useState()

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('@id_usertable')
          return value
        }
        catch(e) {
          // error reading value
        }
      }

    const onRegisterPressed = async () => {
        const emailError = emailValidator(email.value);
        const passwordError = passwordValidator(password.value);
        
        if (password.value != passwordconfirm.value){
            passwordconfirm.error = "Password doesn't match"
            return
        }
    
        if (emailError || passwordError) {
            var tempEmail = email
            email.error =emailError
            setEmail({...email, tempEmail})
            password.error = passwordError
            setPassword({...password, passwordError})
            return
        }
    
        await fetch('https://monicatool.cloud:3001/api/auth/signup', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.value,
                password: password.value
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            alert(resdata.message)
        })
        .catch((error) => {
            console.log(error)
        })
    
        
    };

    useEffect(()=>{

      }, [firstOpen])

    return (
        <PaperProvider theme={theme}>
            <Portal>
                <Dialog visible={true} style={{width:'50%', alignSelf:'center'}}>
                    <Dialog.Title>
                        <View style={{flexDirection:'column', width:'100%', alignItems:'center'}}>
                            <View>
                                <Text style={{fontSize:20}}>MONICA tool</Text>
                            </View>
                            <View>
                                <Text style={{fontSize:14}}>your ally in monitoring system design</Text>
                            </View>
                        </View>
                    </Dialog.Title>
                    <Dialog.Content>
                        <View style={{flexDirection:'column'}}>
                            <View style={styles.rowDialog}>
                                <Text style={styles.textTitle}>Welcome</Text>
                                <Text style={styles.textSubTitle}>Sign up to get started</Text>
                            </View>
                            <View style={styles.rowDialog}>
                                <TextInput
                                    label="Email"
                                    returnKeyType="next"
                                    value={email.value}
                                    onChangeText={text => setEmail({ value: text, error: '' })}
                                    error={!!email.error}
                                    autoCapitalize="none"
                                    autoCompleteType="email"
                                    textContentType="emailAddress"
                                    keyboardType="email-address"
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                />
                                <HelperText type="error" visible={!!email.error}>
                                    {email.error}
                                </HelperText>
                            </View>
                            <View style={styles.rowDialog}>
                                <TextInput
                                    label="Password"
                                    returnKeyType="done"
                                    value={password.value}
                                    onChangeText={text => setPassword({ value: text, error: '' })}
                                    error={!!password.error}
                                    errorText={password.error}
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                    secureTextEntry
                                />
                                <HelperText type="error" visible={!!password.error}>
                                    {password.error}
                                </HelperText>
                            </View>
                            <View style={styles.rowDialog}>
                                <TextInput
                                    label="Confirm Password"
                                    returnKeyType="done"
                                    value={passwordconfirm.value}
                                    onChangeText={text => setPasswordConfirm({ value: text, error: '' })}
                                    error={!!passwordconfirm.error}
                                    errorText={passwordconfirm.error}
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                    secureTextEntry
                                />
                                <HelperText type="error" visible={!!passwordconfirm.error}>
                                    {passwordconfirm.error}
                                </HelperText>
                            </View>
                            
                            <View style={styles.rowDialog}>
                                <Button mode="contained" 
                                    color="white"
                                    onPress={onRegisterPressed}
                                >
                                    Register
                                </Button>
                            </View>
                                <TouchableOpacity onPress={() => navigation.navigate('SignIn')}>
                                    <Text style={styles.signup}>Do you already have an account? Sign in</Text>
                                </TouchableOpacity>
                        </View>
                    </Dialog.Content>
                </Dialog>
            </Portal>
            <View style={{flex:1, with: useWindowDimensions().width, height:useWindowDimensions().height, backgroundColor: theme.colors.background}}>
            
            </View>
        </PaperProvider>
    );
}

export default SignInScreen

const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      textTransform: 'uppercase',
    },
    textTitle: {
        color:"white",
        fontSize: 20,
        //textTransform: 'uppercase',
    },
    textSubTitle:{
        color:"white",
        fontSize: 14,
    },
    boxParameters :{
        padding: 2
    },
    rowDialog:{
        flex:1,
        paddingVertical:20
    },
    forgot:{
        textAlign: 'right',
        color:'grey',
        textDecorationLine: 'underline'
    },
    signup:{
        textAlign: 'center',
        color:'grey',
        textDecorationLine: 'underline'
    },

});